/* eslint-disable */
export const MAJORS_ENUM = {
  DONORS_TITLE: 1, //لقب المتبرع
  DONOR_DESCRIPTION: 2, //وصف المتبرع
  DONOR_TYPE: 3, //نوع المتبرع
  DONOR_SOURCE: 4, //مصدر المتبرع
  DONOR_AREA: 5, //منطقة المتبرع
  COUNTRIES: 6, // الدول
  CITIES: 7, //المدن
  MEMBERSHIP_STATUS: 8, //صفة العضوية
  BANKS: 9, //البنوك
  BANKS_BRANSHES: 10, //فروع البنوك
  TYPES_OF_CENTER_ACCOUNTS: 11, //انواع حسابات المراكز
  CURRENCIES_USED: 12, //العملات المستخدمة
  RELATIONSHIP_OF_MALES_TO_THE_FATHER: 13, //صلة قرابة الذكور بالاب
  WARRANTY_STATUS: 14, //حالة الكفالة
  MARITAL_STATUS: 15, //الحالة الاجتماعية
  THE_NEED_FOR_THE_FAMILY: 16, //شدة احتياج الاسرة
  GENDER: 17, //الجنس
  RELATIONSHIP_OF_FEMALES_TO_THE_DECEASED_FATHER: 18, //قرابة الإناث بالأب المتوفى
  ACADEMIC_LEVEL: 19, //المستوى العلمي
  QUALIFICATION: 20, //المؤهل العلمي
  JOBS: 22, //الوظائف
  GUARANTEE_PAYMENT_METHOD: 25, //طريقة دفع الكفالة
  GUARANTEE_PAYMENT_MECHANISM: 26, //آلية دفع الكفالة
  FEMALE_SOCIAL_STATUS: 28, //الحالة الاجتماعية للانثى
  THE_WIDOW_MARITAL_STATUS: 29, //الحالة الاجتماعية للارملة
  ACCOMMODATION_TYPE: 32, //نوع السكن
  EXCELLENT_TO_GOOD_SCALE: 33, // مقياس ممتاز الى سيء
  CONSTRUCTION_TYPE: 34, //نوع البناء
  ORPHAN_ACTIVITY_SCALE: 37, //مقياس نشاط اليتيم
  SCHOOL_STATUS: 42, //الحالة الدراسية
  SCHOOL_TYPE: 43, //نوع المدرسة
  CLASSROOM: 44, //الصف الدراسي
  REASONS_FOR_ACADEMIC_FAILURE: 45, //اسباب التقصير الدراسي
  THE_AMOUNT_OF_MEMORIZING_THE_QURAN: 46, //مقدار حفظ القرآن
  REASONS_FOR_ORPHAN_FAILURE_TO_PARTICIPATE_IN_THE_CENTER_ACTIVITIES: 47, //اسباب فشل اليتيم في الاشتراك في انشطة المركز
  MORAL_OBLIGATION: 48, //الالتزام الاخلاقي
  ORPHAN_HEALTH: 49, //صحة اليتيم
  ORPHAN_NEEDS: 50, //احتياجات اليتيم
  FATHER_SOCIAL_STATUS: 63, //الحالة الاجتماعية للاب
  ASSOCIATIONS_AND_ZAKAT_COMMITTEES: 250, //الجمعيات ولجان الزكاة
  PALESTINE_BANKS: 251, //بنوك فلسطين
  PALESTINE_BANKS_BRANSHES: 252, // فروع بنوك فلسطين
  ACCOUNT_STATUS:253,//حالة الحساب
  GOVERNORATES_OF_PALESTINE: 259, //محافظات فلسطين
  TYPES_OF_CHARITABLE_ORGANIZATIONS: 261, //انواع المؤسسات الخيرية
  BANKS_OF_COMMITTEES_AND_ASSOCIATIONS_IN_ENGLISH: 262, //بنوك اللجان والجمعيات بالانجليزي
  BRANCHES_OF_COMMITTEES_AND_ASSOCIATIONS_BANKS_IN_ENGLISH: 263, //فروع بنوك اللجان والجمعيات بالانجليزي
  USER_TYPE: 271, //نوع المستخدمين
  HOW_TO_GET_COMMITTEE: 256, //طريقة التعرف على اللجنة
  DOCUMENTS_OF_ASSOCIATIONS_AND_CHARITABLE_COMMITTEES: 265, //وثائق الجمعيات زاللجان الخيرية
  HOW_TO_SEND_MAIL: 267, //طريقة ارسال البريد
  FAMILY_CLASSIFICATION:283,//تصنيف الاسرة
  FOLLOW_UP_NOTES: 284, //ملاحظات متابعة الكافلين
  FAMILY_TYPE: 285, //انواع الاسر
  COMMUNICATION_TYPE:257, //طريقة التواصل
  CONTACT_SUBJECT:255, //موضوع الاتصال
  SPONSORSHIP_NOTES:254, //ملاحظات الكفالة
  COMMITEE_BRANCHES:260, //فروع اللجنة
  REASONS_FOR_STOPPING_WARRANTY: 268, //اسباب وقف الكفالة
  EXCHANGE_CONDITION:24, //حالة الصرف
  SPONSORSHIP_PAYMENT_TYPE:25, // طريقة الدفع للكفالة
  PAYMENT_TYPE_DESCRIPTION:26, // وصف طريقة الدفع
  BALANCE_AMOUNT_OF_SPONSORSHIP:282, // بترصيد مبلغ الكفالة
  REASONS_FOR_CONTINUING_WARRANTY: 277, //اسباب استمرار الكفالة
  FILTER_SPONSORSHIP_CONDITION:70, //فلتر حالة الكفالة
}


export const TABLES_ENUM = {
  //الهيئات الادارية و حسابات البنوك
  ADVISORY_VOLUNTARY_ORGANIZATIONS: 1, //الهيئات الاستشارية و التطوعية
  BANK_ACCOUNTS: 2, //حسابات البنوك

  //المستندات و الكشوفات
  DOCUMENTS_AND_STATEMENTS: 3, //المستندات و الكشوفات

  //بحث اللجان و الجمعيات الخيرية
  COMMITTEES_AND_CHARITIES_SEARCH: 4,

  //الأيتام المكفولين
  ORPHAN_REPORTS_1: 5, //تقارير الايتام (الجدول الاول)
  ORPHAN_REPORTS_2: 6, //تقارير الايتام (الجدول الثاني)
  ORPHAN_PAYMENTS: 7, //دفعات اليتيم
  ORPHAN_ALLOWANCES: 8, //مخصصات اليتيم
  ORPHAN_EXPENSES: 9, //مصروفات اليتيم
  ORPHANS_FAMILIES_ACCOUNT_NUMBER: 10, //اسر الأيتام/ارقام الحسابات
  SPONSORSHIP_DATA: 12, //بيانات الكفالة
  SPONSOR_COMMUNICATION: 13, //تواصل الكافل
  SPONSORED_ACCOUNT_NUMBERS: 14, //ارقام الحسابات

  //الأيتام غير المكفولين
  ACOUNTS_NUMBER: 11, //ارقام الحسابات

  //طلبات توقيف كفالات الايتام
  REQUESTS_TO_STOP_ORPHANS_SPONSORSHIP: 15,

  //متابعة الايتام كبار السن
  FOLLOW_UPON_OLDERLY_ORPHANS: 16,

  //استبدال كفالات الأيتام
  ORPHANS_INFO: 17,
  SPONSOR_INFO: 18,

  //كفالات الأيتام المستبدلة
  SPONSORING_ORPHANS_REPLACED: 19,

  //الأبحاث الدورية للأيتام
  FAMILY_ORPHAN: 20,

  //متابعة الكافلين
  FOLLOW_THE_SPONSOR: 21,

  //ترجمة الأسماء العربية
  ARABIC_NAMES_TRANSLATION: 22,

  //الأسر الفقيرة المكفولة
  POOR_FAMILIES_ACCOUNT_NUMBERS: 23, //أرقام الحسابات
  FAMILY_NOTES: 24, //ملاحظات الاسرة
  FAMILY_GUARANTEES: 25, //كفالات الاسرة
  FAMILY_PAYMENTS: 26, //دفعات الاسرة
  FAMILY_ALLOWANCES: 27, //مخصصات الاسرة
  SPONSOR_COMMUNICATION: 28, //تواصل الكافل

  //الأسر الفقيرة غير المكفولة
  UNSUPPORTED_FAMILIES_ACCOUNT_NUMBERS: 29, //ارقام الحسابات
  UNSUPPORTED_FAMILY_NOTES: 30, //ملاحظات الاسرة
  FAMILY_MEMBERS: 31, //افراد الأسرة

  //أفراد الاسر الفقيرة
  POOR_FAMILY_MEMBERS: 32,
  //استمارات  الأيتام
  ORPHANS_FORMS: 50,

  //حجز استمارات الأيتام
  ORPHANS_BOOKING_FORMS: 51,

  //ترشيحات الأسر المحتاجة للمساعدة
  NOMINATION_OF_FAMILIES_IN_NEED_ASSISTANCE: 52,
  //توقيف كفالات الأسر الفقيرة
  ENDING_POOR_FAMILIES_SPONSORSHIP:33,

  //التقرير الدوري لليتيم
  ORPHANS_PERIODIC_REPORT: 53,

  // الكفالات
  ORPHAN_SPONSOR_SHIPS: 70,//الايتام المكفولين
//بطاقة المتبرع
  DONATOR_NOTE:71,//ملاحظات المتبرع
//تكفيل الايتام
  ADD_ORPHAN_SPONSOR_SHIPS:72,

}

export const ACTION_ENUM = {
  ADD: 1,
  UPDATE: 2,
  PREVIEW: 3,
}

export const STEP_MOVEMENT = {
  NEXT_STEP: 1,
  PRE_STEP: 2,
  NEXT_MANY_STEP: 3,
  PRE_MANY_STEP: 4,
}
