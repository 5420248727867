/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  centerid: null,
  centername: ' ',
  orphanfamilyid: null,
  supplierfullname: ' ',
  supplierrelationship: null,
  supplierrelationshiptext: ' ',
  suppliernationalnumber: ' ',
  orphanid: null,
  firstname: ' ',
  nationalnumber: null,
  sex: null,
  sextext: ' ',
  birthdate: ' ',
  birthplace: null,
  relatedtocenter: null,
  inactive: null,
  orphanfullname: ' ',
  approved: true,
  userid: null,
  creationuserid: null,
  extraorphanid: ' ',
  orphansponsorshipstate: null,
  orphansponsorshipstatetext: ' ',
  committeeid: null,
  committeename: '  ',
  committeeareaid: null,
  committeeareaidtext: ' ',
  quranmemorize: null,
  quranmemorizetext: '  ',
  orphanhealth: null,
  orphanhealthtext: ' ',
  orphanhealthnote: null,
  note: null,
  brothernum: null,
  supportednum: null,
  schoolstage: null,
  schoolstagetext: ' ',
  schoolname: null,
  hobby: null,
  reservation: null,
  reservationtext: null,
  reservationdate: null,
  reservationlongdate: null,
  orphansponsorshipstopdate: ' ',
  sponsorshipdefaultamount: ' ',
  managerialdefaultdeduction: ' ',
  researcheropinion: null,
  pic: null,
  birthcertification: null,
  printed: null,
  orphansponsorshipstartstopdate: ' ',
  newreportdemandsent: null,
  orphansponsorshipstopreason: null,
  firstnameenglish: ' ',
  orphanfullnameenglish: ' ',
  notApprovedOrphanAction: ACTION_ENUM.PREVIEW,
}
export const NotApprovedMainData = createSlice({
  name: 'visibleNotApprovedOrphansData',
  initialState: { visibleNotApprovedOrphansData: initialValue },
  reducers: {
    setNotApprovedOrphanData: (state, action) => {
      state.visibleNotApprovedOrphansData = action.payload
    },
    cleanUpNotApprovedOrphanData: (state) => {
      state.visibleNotApprovedOrphansData = initialValue
    },
  },
})

export const { setNotApprovedOrphanData, cleanUpNotApprovedOrphanData } =
  NotApprovedMainData.actions
export default NotApprovedMainData.reducer
