/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    show: false,
}
export const modalSlice = createSlice({
    name: "visible",
    initialState: { visible: initialValue },
    reducers: {
        showModal: (state, action) => {
            state.visible = action.payload;
        }
    }
})

export const { showModal } = modalSlice.actions
export default modalSlice.reducer;
