/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  nameprefixtext: { value: '', label: '' },
  donatorid: null,
  fullname: '',
  namesuffixtext: { value: '', label: '' },
  contactprefixtext: { value: '', label: '' },
  contactfullname: '',
  contactsuffixtext: { value: '', label: '' },
  invitationnameprefixtext: { value: '', label: '' },
  invitationname: '',
  invitationnamesuffixtext: { value: '', label: '' },
  mediatorname: '',
  mediatorid: null,
  donatortypetext: { value: '', label: '' },
  donationsource: { value: 126, label: 'داخلية' },
  name: { value: '', label: '' },
  donationarea: { value: '', label: '' },
  address: '',
  addresswork: '',
  telhome: null,
  telwork1: null,
  telwork2: null,
  mobile: null,
  mobilecode: { value: '', label: '' },
  mobileowner: '',
  mobile2: null,
  mobilecode2: { value: '', label: '' },
  mobileowner2: '',
  fax: '',
  email: '',
  knowingcommittee: { value: '', label: '' },
  citytext: { value: '', label: '' },
  countrytext: { value: '', label: '' },
  sendmailway: { value: '', label: '' },
  openbalance: null,
  newflag: null,
  note: '',
  zipcode: '',
  pobox: '',
  followedby: { value: '', label: '' },
  sendsms: null,

  medmobile: '',
  medmobilecode: { value: '', label: '' },
  medmobileowner: '',

  medmobile2: '',
  medmobilecode2: { value: '', label: '' },
  medmobileowner2: '',

  medmobile: '',
  medmobilecode: { value: '', label: '' },
  medmobileowner: '',
  medmobile2: '',
  medmobilecode2: { value: '', label: '' },
  medmobileowner2: '',
  mediatoremail: '',

  action: ACTION_ENUM.PREVIEW,
}
export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainData: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainData: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainData, cleanUpMainData } = theMainData.actions
export default theMainData.reducer
