/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recid: null,
  centerid: null,
  centername: '',
  orphanfamilyid: null,
  orphanid: null,
  orphanfullname: '',
  donatorid: null,
  fullname: '',
  paymentstate: null,
  paymentstatetext: '',
  paymentstopreason: '',
  sponsorshipstate: null,
  sponsorshipstatetext: '',
  sponsorshipamount: '',
  managerialdeduction: '',
  maincenterdeduction: '',
  educationdeduction: '',
  mediactiondeduction: '',
  otherdeduction: '',
  sponsorshipdate: '',
  sponsorshipstartdate: '',
  reportingperiod: null,
  paymentmethod: null,
  paymentmethodtext: '',
  paymenttype: null,
  paymenttypetext: '',
  note: '',
  inactive: null,
  approved: null,
  sponsorshipnetamount: '',
  sponsorshipstopdate: null,
  sponsorshiplongstopdate: null,
  remindingperiod: null,
  extraorphanid: null,
  branch: null,
  branchtext: '',
  sponsorshipenddate: null,
  sponsorshipendlongdate: null,
  continuesponsorshipreason: null,
  allowbalance: null,
  allowbalancetext: '',
  followupnote: null,
  followupnotetext: null,
  sponsorshipAction: ACTION_ENUM.PREVIEW,
}
export const SponsorshipData = createSlice({
  name: 'visibleSponsorshipData',
  initialState: { visibleSponsorshipData: initialValue },
  reducers: {
    setSponsorshipData: (state, action) => {
      state.visibleSponsorshipData = action.payload
    },
    cleanUpSponsorshipData: (state) => {
      state.visibleOrpvisibleSponsorshipDatahansData = initialValue
    },
  },
})

export const { setSponsorshipData, cleanUpSponsorshipData } = SponsorshipData.actions
export default SponsorshipData.reducer
