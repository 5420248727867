/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   id: null,
  }

export const RowSelectionSlice = createSlice({
  name: 'row',
  initialState: { id: initialState },
  reducers: {
    setId: (state, action) => {
        state.id = action.payload
    }
  },
})

export const { setId } = RowSelectionSlice.actions

export default RowSelectionSlice.reducer
