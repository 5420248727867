/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
    mediatorid: null,
    nameprefix: { value: '', label: '' },
    fullname: '',
    namesuffix: { value: '', label: '' },
    donatortype: { value: '', label: '' },
    followedby:{ value: '', label: '' },
    donationarea:{ value: '', label: '' },
    address: '',
    addresswork: '',
    pobox: null,
    zipcode: null,
    city: { value: '', label: '' },
    country: { value: '', label: '' },
    telhome: null,
    telwork1: null,
    telwork2: null,
    fax: null,
    email: '',
    note: '',
    mobile: null,
    mobilecode: { value: '', label: '' },
    mobileowner: '',
    mobile2: null,
    mobilecode2: { value: '', label: '' },
    mobileowner2: '',
    knowingcommittee: { value: '', label: '' },
  action: ACTION_ENUM.PREVIEW,
}
export const brokerCard = createSlice({
  name: 'visibleBrokerCard',
  initialState: { visibleBrokerCard: initialValue },
  reducers: {
    setBrokerCard: (state, action) => {
      state.visibleBrokerCard = action.payload
    },
    cleanUpBrokerCard: (state) => {
      state.visibleBrokerCard = initialValue
    },
  },
})

export const { setBrokerCard, cleanUpBrokerCard } = brokerCard.actions
export default brokerCard.reducer