/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  orphanfamilyid: null,
  identifier1phone: null,
  identifier2phone: null,
  recid: null,
  supplierfullname: '',
  supplierfullnameenglish: '',
  suppliernationalnumber: '',
  supplierrelationship: { value: '', label: '' },
  // supplierrelationshiptext: null,
  suppliersex: { value: '', label: '' },
  // suppliersextext: null,
  familyneedmeter: { value: '', label: '' },
  // familyneedmetertext: null,

  sponsorshipstate: { value: '', label: '' },
  // sponsorshipstatetext: null,

  classification: { value: '', label: '' },
  // classificationtext: null

  committeeid: '',

  committeeareaid: { value: '', label: '' },
  // committeeareaidtext: null,

  fatherfirstname: '',
  fathersecondname: '',
  fatherthirdname: '',
  familyname: '',

  fatherfirstnameenglish: '',
  fathersecondnameenglish: '',
  fatherthirdnameenglish: '',
  familynameenglish: '',

  fathernationalnumber: '',
  fatherdeathdate: '',
  fatherdeathreason: '',

  motherfullname: '',
  mothernationalnumber: '',
  mothermaritalstatus: { value: '', label: '' },
  // mothermaritalstatustext: null,

  motherjob: '',
  motherincome: '',

  city: { value: '', label: '' },
  // citytext: null,
  neighborhood: '',

  // رامي
  street: '',
  nearestphone: '',

  researcherrecomendation: '',
  note: '',

  fatherdeathcertification: null,
  motherdeathcertification: null,

  supplierid: null,
  guardianshippaper: null,

  action: ACTION_ENUM.PREVIEW,
}
export const theMainData = createSlice({
  name: 'visibleMainData',
  initialState: { visibleMainData: initialValue },
  reducers: {
    setMainData: (state, action) => {
      state.visibleMainData = action.payload
    },
    cleanUpMainDataFamilies: (state) => {
      state.visibleMainData = initialValue
    },
  },
})

export const { setMainData, cleanUpMainDataFamilies } = theMainData.actions
export default theMainData.reducer
