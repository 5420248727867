/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {edit: false};

export const EditOnClickSlice = createSlice({
    name: 'visible',
    initialState: { visible: initialState },
    reducers: {
      editable: (state, { payload }) => {
        state.visible = { ...payload }
      },
    },
  })

export const { editable } = EditOnClickSlice.actions

export default EditOnClickSlice.reducer;
