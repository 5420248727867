/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  orphanfamilyid: null,
  committeeid: { value: null, label: null },
  committeeareaid: { value: null, label: null },
  supplierfullname: null,
  orphanid: null,
  orphanfullname: null,
  sex: { value: null, label: null },
  birthdate: null,
  birthplace: null,
  nationalnumber: null,
  supplierrelationship: { value: null, label: null },
  schoolstage: { value: null, label: null },
  orphanhealthnote: null,
  note: null,
  motherincome: null,
  fatherjob: null,
  mothermaritalstatus: { value: null, label: null },
  familydependent:false,
  action: ACTION_ENUM.PREVIEW,
}
export const orphanFamilyMember = createSlice({
  name: 'visibleOrphanfamilyMember',
  initialState: { visibleOrphanfamilyMember: initialValue },
  reducers: {
    setOrphanfamilyMember: (state, action) => {
      state.visibleOrphanfamilyMember = action.payload
    },
    cleanUpOrphanfamilyMember: (state) => {
      state.visibleOrphanfamilyMember = initialValue
    },
  },
})

export const { setOrphanfamilyMember, cleanUpOrphanfamilyMember } = orphanFamilyMember.actions
export default orphanFamilyMember.reducer
