/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,

  orphanfamilyid: null,
  orphanid: null,
  donatorid: null,

  centerid: null,
  //الفرع
  // "branch": 11141,
  // "branchtext": "المكتب الرئيسي/ عمان",
  branch: { value: 11143, label: 'المكتب الرئيسي/ عمان' },

  //قيمة الكفالة
  sponsorshipamount: 0,

  //ادارية
  managerialdeduction: 1,
  //اخري
  otherdeduction: 0,

  //بداية الكفالة
  sponsorshipstartdate: null,
  //تاريخ الكفالة
  sponsorshipdate: null,

  //طريقة الدفع

  // "paymentmethod": 201,
  // "paymentmethodtext": "سنوي",

  //صافي الكفالة
  sponsorshipnetamount: 0,

  paymentmethod: { value: '', label: '' },
  //طريقة الدفع  وصف
  // "paymenttype": 100,
  // "paymenttypetext": "ايداع بنكي",

  paymenttype: { value: '', label: '' },
  paymentstate: { value: '', label: '' },



  //فترة التقرير
  reportingperiod: 12,

  //فترة التذكير
  remindingperiod: null,

  //ملاحظات
  note: null,

  empaction: ACTION_ENUM.PREVIEW,
}

export const OrphanSponsorShips = createSlice({
  name: 'visibleOrphanSponsorShips',
  initialState: { visibleOrphanSponsorShips: initialValue },
  reducers: {
    setOrphanSponsorShips: (state, action) => {
      state.visibleOrphanSponsorShips = action.payload
    },
    cleanUpOrphanSponsorShips: (state) => {
      state.visibleOrphanSponsorShips = initialValue
    },
  },
})

export const { setOrphanSponsorShips, cleanUpOrphanSponsorShips } = OrphanSponsorShips.actions
export default OrphanSponsorShips.reducer
