/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   table_id: null,
   isSaved: false,
   putArr: [],
   postArr: [],
  }

export const PutPostSlice = createSlice({
  name: 'table',
  initialState: { data: initialState },
  reducers: {
    setdata: (state, action) => {
        state.data = action.payload
    }
  },
})

export const { setdata } = PutPostSlice.actions

export default PutPostSlice.reducer
