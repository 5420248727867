/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { ACTION_ENUM } from 'src/common/Enum'

const initialValue = {
  recId: null,
  centerid: null,
  centername: '',
  nationalnumber:null,
  socialsecurity:null,
  employeenumber: null,
  employeeid: null,
  name: '',
  birthdate: '',
  birthplace: '',
  scientificqualification: null,
  sientificdescription: '',
  address: '',
  hometelephone: null,
  mobile: null,
  hiredate: '',
  employeetype: null,
  employeetypedescription: '',
  inactive: null,
  userid: null,
  committeeid: null,
  committeename: '',
  username: '',
  password: '',
  position: null,
  positiondescription: '',
  empaction: ACTION_ENUM.PREVIEW,
}
export const EmployeesSlice = createSlice({
  name: 'visibleEmployeeData',
  initialState: { visibleEmployeeData: initialValue },
  reducers: {
    setEmployeeData: (state, action) => {
      state.visibleEmployeeData = action.payload
    },
    cleanUpEmployeeData: (state) => {
      state.visibleEmployeeData = initialValue
    },
  },
})

export const { setEmployeeData, cleanUpEmployeeData } = EmployeesSlice.actions
export default EmployeesSlice.reducer
