/* import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
 */
/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit'

import departmentModalReducer from 'src/redux/Reducer/departmentReducer/ModalSlice'
import departmentDeleteModalReducer from 'src/redux/Reducer/departmentReducer/DeleteSlice'

import { upsertServiceReducer } from './redux/Reducer/servicesReducers'
import { deleteServiceReducer } from './redux/Reducer/servicesReducers'
import showMedReducer from './redux/Reducer/MedicineReducer/showMedSlice'
import deleteMedReducer from './redux/Reducer/MedicineReducer/deleteMedSlice'

import { masterLayoutReducer } from './redux/Reducer'
import { navReducer } from './redux/Reducer/navReducer'
import { modal } from './redux/Reducer'
import { theMainData } from './redux/Reducer/committeesAndCharities'
import { theMainDataDonators } from './redux/Reducer/donators'

import { minorListReducer } from './redux/Reducer/minorReducer'
import { searchReducer } from './redux/Reducer'
import employeeReducer from './redux/Reducer/Employees/EmployeesSlice'
import OrphanSponsorShipsReducer from './redux/Reducer/OrphanSponsorShips/OrphanSponsorShipsSlice'
import SponsoredOrphanReducer from './redux/Reducer/SponsoredOrphans/OrphansMainDataSlice'
import SponsoredOrphanFamilyDataReducer from './redux/Reducer/SponsoredOrphans/OrphanFamilyDataSlice'
import editTableReducer from './redux/Reducer/EditableTable/EditOnClickSlice'
import rowSelectionReducer from './redux/Reducer/EditableTable/RowSelectionSlice'
import put_postReducer from './redux/Reducer/EditableTable/PutPostSlice'
import notApprovedOrphanReducer from './redux/Reducer/UnSponsoredOrphans/NotApprovedMainData'
import { theMainDataOrphansFamilies } from './redux/Reducer/orphansfamilies'
import { theMainDataOrphansFamiliesAccounts } from './redux/Reducer/orphansfamiliesAccounts'
import NotAppFamilyDataReducer from './redux/Reducer/UnSponsoredOrphans/NotApprovedFamilyData'
import SponsorshipDataReducer from './redux/Reducer/SponsoredOrphans/SponsorshipData'
import { brokerCard } from './redux/Reducer/brokerCard'
import { callingCode } from './redux/Reducer/callingCodes'
import { orphanFamilyMember } from './redux/Reducer/orphanFamilyMember'
const store = configureStore({
  reducer: {
    departmentDeleteModal: departmentDeleteModalReducer,
    departmentModal: departmentModalReducer,
    addServicesModal: upsertServiceReducer,
    deleteServicesModal: deleteServiceReducer,
    UpsertMedicineModal: showMedReducer,
    theMainDataOrphansFamilies: theMainDataOrphansFamilies,
    theMainDataOrphansFamiliesAccounts: theMainDataOrphansFamiliesAccounts,
    deleteMedModal: deleteMedReducer,
    masterLayoutReducer: masterLayoutReducer,
    navReducer: navReducer,
    modal: modal,
    table: put_postReducer,
    theMainData: theMainData,
    theMainDataDonators: theMainDataDonators,
    minorListReducer: minorListReducer,
    searchReducer: searchReducer,
    OrphanSponsorShipsReducer:OrphanSponsorShipsReducer,
    employeeData: employeeReducer,
    editTable: editTableReducer,
    row: rowSelectionReducer,
    orphanData: SponsoredOrphanReducer,
    orphanFamilyData: SponsoredOrphanFamilyDataReducer,
    notApprovedOrphanMainData: notApprovedOrphanReducer,
    notAppFamilyData: NotAppFamilyDataReducer,
    sponsorshipData: SponsorshipDataReducer,
    brokerCard: brokerCard,
    callingCode: callingCode,
    orphanFamilyMember : orphanFamilyMember,
  },
})
export default store
